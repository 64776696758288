<template>
  <div class="full-width">
    <app-bar-web-drafts v-if="tab === 'websites'" />
    <app-bar-mobile-drafts v-else-if="tab === 'app'" />
    <new-app-bar-simple
      v-else-if="tab === 'assets'"
      :title="$trans('assets')"
      :subtitle="$trans('section_admin_panel_tools')"
    />
    <app-bar-documents-generator v-else-if="tab === 'documents'" />
    <app-bar-log-list v-else-if="tab === 'logs'" />
  </div>
</template>

<script>
import AppBarWebDrafts from "@/views/dashboard/pages/Tools/AppBar/AppBarWebsiteDrafts.vue";
import AppBarMobileDrafts from "@/views/dashboard/pages/Tools/AppBar/AppBarMobileDrafts.vue";
import NewAppBarSimple from "@/views/dashboard/components/AppBar/AppBarViews/NewAppBarSimple.vue";
import AppBarDocumentsGenerator from "@/views/dashboard/pages/Tools/AppBar/AppBarDocumentsGenerator.vue";
import AppBarLogList from "@/views/dashboard/pages/Tools/AppBar/AppBarLogList.vue";

export default {
  components: {
    AppBarLogList,
    AppBarDocumentsGenerator,
    NewAppBarSimple,
    AppBarMobileDrafts,
    AppBarWebDrafts,
  },
  computed: {
    tab() {
      if (this.$route.params) {
        return this.$route.params.tab;
      }
      return null;
    },
  },
};
</script>
